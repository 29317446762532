<template>
  <div
    style="background: #323563;color: #ffffff;padding: 20px;margin-top: 6vh;"
    :key="componentKey"
  >
    <div class="col-12 col-md-8 offset-md-2 my-10">
      <div class="row">
        <div class="col-12 mb-5 text-section" style="font-weight: 700;">
          {{ syllabus ? syllabus.name : "" }}
        </div>
        <!-- {{ syllabus }} -->
        <div class="col-12 col-md-12 col-lg-5 px-5">
          <img
            :src="
              syllabus && syllabus.thumbnailsPath
                ? imageUrl + syllabus.thumbnailsPath
                : 'media/image-app/no-image.svg'
            "
            alt=""
            class="w-100"
            @error="setAltImg"
            style="border-radius: 20px;"
          />
          <p class="mt-5 text-category">
            {{ syllabus ? syllabus.description : "" }}
          </p>
        </div>
        <div class="col-12 col-md-12 col-lg-7 px-5">
          <div>
            <div style="background: #57597f;border-radius: 10px;padding: 10px;">
              <div style="display: flex;justify-content: space-between;">
                <b class="text-white text-title" style="font-weight: 700;"
                  >ความสำเร็จ</b
                >
                <b>
                  <span class="text-title" style="color:#DA595A;">{{
                    syllabusRegister ? syllabusRegister.courseSuccess : 0
                  }}</span>
                  /
                  <span>{{ syllabus ? syllabus.course.length : 0 }}</span></b
                >
              </div>
              <div
                v-if="syllabusRegister"
                class="d-flex align-items-center flex-column mt-3 w-100"
              >
                <div
                  class="d-flex justify-content-between fw-bold fs-6 text-dark w-100 mt-auto mb-2"
                >
                  <span class="text-white">ภาพรวม</span>
                  <span class="text-white"
                    >{{
                      syllabusRegister && syllabusRegister.progressUpdate
                        ? syllabusRegister.progressUpdate
                        : 0
                    }}%</span
                  >
                </div>
                <div
                  class="h-8px mx-3 w-100 rounded"
                  style="background:#E1F2FF"
                >
                  <div
                    class="bg-progressbar rounded h-8px"
                    data-bs-toggle="tooltip"
                    :title="syllabusRegister.progressUpdate + '%'"
                    role="progressbar"
                    :style="'width: ' + syllabusRegister.progressUpdate + '%'"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div
                v-else
                class="d-flex align-items-center flex-column mt-3 w-100"
              >
                <div
                  class="d-flex justify-content-between fw-bold fs-6 text-dark w-100 mt-auto mb-2"
                >
                  <span class="text-white">ภาพรวม</span>
                  <span class="text-white">0%</span>
                </div>
                <div
                  class="h-8px mx-3 w-100 rounded"
                  style="background:#E1F2FF"
                >
                  <div
                    class="bg-progressbar rounded h-8px"
                    data-bs-toggle="tooltip"
                    title="0%'"
                    role="progressbar"
                    style="width: 0%"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div
                class="my-5"
                v-if="!syllabusRegister"
                style="display:flex;align-items: center; "
              >
                <button
                  type="button"
                  @click="registerSyllabus()"
                  class="btn "
                  style="min-width: 227px;background: #DA595A;color: #ffffff;border-radius: 21px;"
                >
                  ลงทะเบียน
                </button>
              </div>
              <div class="row my-5">
                <div
                  class="col"
                  v-if="
                    syllabusRegister && syllabusRegister.progressUpdate == 100
                  "
                >
                  <!-- {{ currentSpecialistStatus }} -->
                  <button
                    v-if="
                      currentSpecialistStatus == 2 && listRegister.length > 0
                    "
                    class="btn w-100 mb-2"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_answers"
                    style="background: #87BAAB;color:#fff;border-radius: 21px;"
                  >
                    จองคิวเข้าสอบวัดผลแล้ว
                  </button>
                  <br />
                  <span v-if="listRegister.length > 0" style="text-category"
                    >วันที่
                    {{ formatDate(listRegister[0].examRoomProfile.startDate) }}
                    ({{
                      checkStartDate(
                        listRegister[0].examRoomProfile.startDate,
                        listRegister[0].examRoomProfile.endDate
                      )
                    }})
                    <br />
                    {{ listRegister[0].examRoomProfile.examCenterProfile.name }}
                  </span>
                  <div
                    v-else-if="
                      currentSpecialistStatus == 2 && listRegister.length == 0
                    "
                  >
                    <div
                      v-if="
                        syllabusRegister.certStatus == 1 &&
                          syllabusRegister.status == 4
                      "
                    >
                      <button
                        class="btn"
                        style="background: #FFC700;color:#fff;border-radius: 21px;"
                      >
                        รออนุมัติใบรับรอง
                      </button>
                    </div>

                    <a
                      href="#"
                      v-else-if="
                        syllabusRegister.certStatus == 2 &&
                          syllabusRegister.status == 4
                      "
                      class="btn text-btn w-100 mb-2"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_cert"
                      @click="getCertificateSyllabusId(syllabusRegister.id)"
                      style="border-radius: 21px;background: #31B67C;color: #FFF;"
                      >ดาวน์โหลดใบรับรอง</a
                    >
                    <button
                      class="btn w-100 mb-2"
                      v-else
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_answers"
                      style="background: #9999D3;
                            border-radius: 21px;"
                    >
                      จองคิวเข้าสอบวัดผล
                    </button>
                  </div>
                  <div v-else>
                    <button
                      class="btn w-100 mb-2"
                      @click="alert()"
                      style="background: #9999D3;
                            border-radius: 21px;"
                    >
                      จองคิวเข้าสอบวัดผลแล้ว
                    </button>
                    <br />
                    <span v-if="listRegister.length > 0" style="text-category"
                      >วันที่
                      {{
                        formatDate(listRegister[0].examRoomProfile.startDate)
                      }}
                      ({{
                        checkStartDate(
                          listRegister[0].examRoomProfile.startDate,
                          listRegister[0].examRoomProfile.endDate
                        )
                      }})
                      <br />
                      {{
                        listRegister[0].examRoomProfile.examCenterProfile.name
                      }}
                    </span>
                  </div>
                </div>
                <div
                  class="col"
                  v-if="
                    syllabusRegister && syllabusRegister.progressUpdate == 100
                  "
                >
                  <!-- {{ currentSpecialistStatus }} -->
                  <button
                    v-if="
                      currentSpecialistStatus == 2 && listRegister.length > 0
                    "
                    @click="
                      registerExamRoomBtn(
                        listRegister[0].examRoomId,
                        listRegister[0].examRoomProfile.flgPwd,
                        listRegister[0].examRoomProfile.photo,
                        listRegister[0].examRoomProfile.examRoomCode
                      )
                    "
                    type="button"
                    class="btn w-100 mb-2"
                    style="background: var(--323563, #323563);color:#fff;border-radius: 21px;"
                  >
                    เข้าสู่การสอบ
                  </button>
                </div>
              </div>
            </div>
            <div
              class="my-5"
              style="display: flex;justify-content: space-between;flex-wrap: wrap;"
            >
              <div>
                <p class="text-category">
                  ลงทะเบียนเรียนเมื่อ
                  <span class="text-category" style="color:#9999D3">{{
                    formatDate(
                      syllabusRegister ? syllabusRegister.createDate : null
                    )
                  }}</span>
                </p>
                <p class="text-category">
                  เข้าสู่ระบบครั้งแรก
                  <span class="text-category" style="color:#9999D3">
                    {{
                      formatDate(
                        syllabusRegister ? syllabusRegister.createDate : null
                      )
                    }}</span
                  >
                </p>
                <p class="text-category">
                  ระยะเวลาในการเรียน
                  <span class="text-category" style="color:#9999D3"
                    >{{ syllabus ? syllabus.duration : "-" }} วัน</span
                  >
                </p>
              </div>
              <div>
                <p class="text-category">
                  หมดอายุ
                  <span class="text-category" style="color:#9999D3">
                    {{ formatDate(expireDate ? expireDate : null) }}</span
                  >
                </p>
                <p class="text-category">
                  สถานะ
                  <span
                    class="text-category"
                    style="color:#9999D3"
                    v-if="syllabusRegister"
                  >
                    {{
                      syllabusRegister && syllabusRegister.progressUpdate == 100
                        ? "จบหลักสูตร"
                        : "กำลังศึกษา"
                    }}</span
                  >
                  <span class="text-category" style="color:#9999D3" v-else>
                    -
                  </span>
                </p>
                <p
                  v-if="syllabus && syllabus.register == true"
                  class="text-category"
                  style="font-weight: 700;
                          color: #DA595A;"
                >
                  เหลือเวลาอีก {{ countDownTime }}
                </p>
              </div>
            </div>
          </div>
          <div class="my-5" v-if="syllabus && syllabus.pdfPath.length > 0">
            <button
              class="btn"
              type="button"
              @click="fileShow()"
              style="border: 1px solid #E9E9EF;color:#E9E9EF;
                        border-radius: 21px;"
            >
              เอกสารประกอบหลักสูตร
            </button>
            <div
              v-if="syllabus && syllabus.pdfPath.length > 0 && fileDownload"
              class="my-3"
            >
              <div
                v-for="(item, i) in syllabus.pdfPath"
                class="my-1"
                :key="i"
                style="display: flex;flex-wrap: wrap;justify-content: space-between;"
              >
                <div>
                  <i class="fa fa-file-alt"></i>
                  {{
                    item.name.split("C:\fakepath").length > 0
                      ? item.name.split("C:\fakepath")[1]
                      : item.name
                  }}
                  {{ item.name }}
                </div>
                <a :href="imageUrl + item.fileName" target="_blank">
                  <u> ดาวน์โหลด </u>
                </a>
              </div>
            </div>
            <div
              v-if="syllabus && syllabus.pdfPath.length == 0 && fileDownload"
              class="my-3"
            >
              ไม่มีข้อมูลเอกสารประกอบหลักสูตร
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--begin::Dashboard-->
  <div class="content flex-row-fluid">
    <div class="p-5 container">
      <div class="mb-5 row">
        <div class="col-12 col-lg mb-2">
          <label for="" class="text-category" style="color:#9999D3"
            >หมวดหมู่</label
          >
          <el-select
            size="large"
            class="w-100"
            filterable
            v-model="filterForm.category"
            @change="searchData"
            placeholder="หมวดหมู่"
            autocomplete="one-time-code"
          >
            <el-option value="" label="ทั้งหมด"></el-option>
            <el-option
              v-for="ct in categoryList"
              :key="ct.categoryCode"
              :value="ct.categoryCode"
              :label="ct.categoryName[0].data"
            />
          </el-select>
        </div>
        <!-- <div class="col-12 col-lg mb-2">
          <label for="" class="text-category" style="color:#9999D3"
            >สถานะ</label
          >
          <el-select
            size="large"
            class="w-100"
            filterable
            v-model="filterForm.status"
            @change="searchData"
            placeholder="สถานะ"
          >
             <el-option value="" label="ทั้งหมด"></el-option>
          <el-option value="-1" label="ยังไม่ได้ลงทะเบียน"></el-option>
          <el-option value="0" label="เรียนต่อ"></el-option>
          <el-option value="3" label="จบหลักสูตร"></el-option>
          </el-select>
        </div> -->
        <div class="col-12 col-lg mb-2">
          <label for="" class="text-category" style="color:#9999D3"
            >เรียงตาม</label
          >
          <el-select
            size="large"
            class="w-100"
            autocomplete="one-time-code"
            filterable
            v-model="filterForm.orderBy"
            @change="searchData"
            placeholder="เรียงตาม"
          >
            <el-option value="" label="ทั้งหมด"></el-option>
            <el-option value="2" label="เรียงตามตัวอักษร"></el-option>
            <el-option value="3" label="ใหม่ล่าสุด"></el-option>
            <el-option value="4" label="ยอดนิยม"></el-option>
            <el-option value="1" label="แนะนำ"></el-option>
          </el-select>
        </div>
        <div class="col-12 col-lg mb-2">
          <label for="" class="text-category" style="color:#9999D3;"
            >ค้นหา</label
          >
          <input
            class="form-control"
            type="text"
            v-model="filterForm.search"
            @change="searchData"
            style="height:40px"
            autocomplete="one-time-code"
            placeholder="ค้นหา"
          />
        </div>
      </div>
      <div class="row mb-5" v-if="listData && listData.length > 0">
        <div
          class="col-12 col-lg-4 mb-5"
          style="padding: 10px;"
          v-for="(item, index) in listData"
          :key="index"
        >
          <router-link
            :to="'/course/mandatory-detail/' + route.params.id + '/' + item.id"
          >
            <div
              class="card box-item"
              style="border-radius: 10px;position: relative;border: 1px solid #E9E9EF;min-height: 300px"
            >
              <div style="position: relative;">
                <img
                  :src="imageUrl + item.thumbnailsPath"
                  @error="setAltImg"
                  alt=""
                  class="w-100"
                  style="border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            object-fit: cover;"
                />
                <div
                  style="position: absolute;left: 10px;bottom: 10px;display: flex;flex-direction: row;
                    flex-wrap: nowrap;
                    overflow-x: hidden;"
                >
                  <div
                    style="margin-right:5px"
                    v-for="(itemHas, index) in item.hashtag"
                    :key="index"
                  >
                    <span class="badge badge-danger">{{ itemHas }}</span>
                  </div>
                </div>
              </div>

              <div class="card-body responsive-body" style="min-height: 153px">
                <div
                  class="mb-5 limit-text text-title"
                  style="font-weight: 400;
                color: #323563;"
                >
                  {{ item.title }}
                </div>
                <Rating :value="item.rate ? item.rate : 0" :max="5" />
                <!-- <div class="text-category" style="color:#9999D3">
                  หมวดหมู่ :
                  <span v-html="renderCategory(item.categoryProfile)"></span>
                </div> -->
                <!-- <div
                  class="mb-5 limit-text text-info"
                  style="font-weight: 400;
                color: #9F9F9F!important;"
                  v-html="removeStyle(item.description)"
                ></div> -->
              </div>
              <div class="card-footer">
                <div
                  class="d-flex align-items-center flex-column mt-3 w-100"
                  v-if="item.register == true && item.progressUpdate != 100"
                >
                  <div
                    class="d-flex justify-content-between fw-bold fs-6 text-dark w-100 mt-auto mb-2"
                  >
                    <span style="font-weight: 600;"
                      >สถานะ :
                      <span v-html="renderStatus(item.statusRegister)"></span
                    ></span>
                    <span>{{ item.progressUpdate }}%</span>
                  </div>
                  <div
                    class="h-8px mx-3 w-100 rounded"
                    style="background:#E1F2FF"
                  >
                    <div
                      class="bg-progressbar rounded h-8px"
                      data-bs-toggle="tooltip"
                      :title="item.progressUpdate + '%'"
                      role="progressbar"
                      :style="'width: ' + item.progressUpdate + '%'"
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div
                  class="d-flex align-items-center flex-column mt-3 w-100"
                  v-else-if="
                    item.register == true && item.progressUpdate == 100
                  "
                >
                  <div
                    class="d-flex justify-content-between fw-bold fs-6 text-dark w-100 mt-auto mb-2"
                  >
                    <span style="font-weight: 600;"
                      >สถานะ :
                      <span v-html="renderStatus(item.statusRegister)"></span
                    ></span>
                    <span>{{ item.progressUpdate }}%</span>
                  </div>
                  <div
                    class="h-8px mx-3 w-100 rounded"
                    style="background:#E1F2FF"
                  >
                    <div
                      class="bg-progressbar rounded h-8px"
                      data-bs-toggle="tooltip"
                      :title="item.progressUpdate + '%'"
                      role="progressbar"
                      :style="'width: ' + item.progressUpdate + '%'"
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div v-else style="display: flex;justify-content: center;">
                  <router-link
                    :to="
                      '/course/mandatory-detail/' +
                        route.params.id +
                        '/' +
                        item.id
                    "
                    type="button"
                    class="btn btn-register2 text-btn"
                  >
                    ลงทะเบียน
                  </router-link>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class=" row mb-5" v-else>
        <img src="media/image-app/no-data.svg" alt="" style="height:300px" />
      </div>
      <div
        v-if="listData && listData.length > 0"
        style="display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                flex-direction: row;"
      >
        <b class="text-category" style="color:#9999D3"
          >จำนวน {{ listData && listData.totalElements }} รายการ</b
        >
        <ul class="pagination">
          <li :class="'page-item previous ' + (page == 0 ? 'disabled' : '')">
            <button type="button" @click="backPage()" class="page-link">
              <i class="previous"></i>
            </button>
          </li>
          <div v-for="(item, index) in totalPage" :key="index">
            <li :class="'page-item ' + (index == page ? 'active' : '')">
              <button type="button" @click="changePage(item)" class="page-link">
                {{ item }}
              </button>
            </li>
          </div>
          <li
            :class="
              'page-item next ' + (page + 1 == totalPage ? 'disabled' : '')
            "
          >
            <button type="button" @click="nextPage()" class="page-link">
              <i class="next"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="kt_modal_answers"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-800px">
      <div class="modal-content">
        <div class="modal-header" id="kt_modal_answers_header">
          <h2>เลือกเวลาสอบวัดผล</h2>
          <label for="" class="text-category" style="color:#9999D3"
            >ศูนย์สอบ</label
          >
          <el-select
            size="large"
            filterable
            v-model="examCenterId"
            @change="getExamRoom()"
            placeholder="ศูนย์สอบ"
            autocomplete="one-time-code"
          >
            <el-option value="" label="กรุณาเลือก"></el-option>
            <el-option
              v-for="exr in examCenterList"
              :key="exr.id"
              :value="exr.id"
              :label="exr.name"
            />
          </el-select>
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
        </div>
        <div
          style="height: 66px;
                display: flex;
                background: rgb(218, 89, 90);
                color: rgb(242, 226, 225);
                align-items: center;
                justify-content: center;"
        >
          การจองคิวสอบ จำเป็นต้องเรียนจนภาพรวมครบ 100% ถึงมีสิทธิ์สอบ
        </div>
        <div class="modal-body py-10 px-lg-17">
          <div class="w-100" v-if="finalExamsList.length > 0">
            <p>
              <span style="font-weight: 700;" class="text-category"
                >ชื่อศูนย์สอบ :
              </span>
              <span class="text-category">{{
                finalExamsList[0].examCenterProfile.name
              }}</span>
            </p>
            <p>
              <span style="font-weight: 700;" class="text-category"
                >ที่อยู่ :
              </span>
              <span class="text-category">{{
                finalExamsList[0].examCenterProfile.addressProfile
                  ? finalExamsList[0].examCenterProfile.addressNo +
                    " " +
                    finalExamsList[0].examCenterProfile.addressProfile
                      .subDistrictTh +
                    " " +
                    finalExamsList[0].examCenterProfile.addressProfile
                      .districtTh +
                    " " +
                    finalExamsList[0].examCenterProfile.addressProfile
                      .provinceTh +
                    " " +
                    finalExamsList[0].examCenterProfile.addressProfile.zipcode
                  : "-"
              }}</span>
            </p>
            <p>
              <span style="font-weight: 700;" class="text-category"
                >เบอร์โทร :
              </span>
              <span class="text-category">{{
                finalExamsList[0].examCenterProfile.phoneNumber
              }}</span>
            </p>
            <p>
              <span style="font-weight: 700;" class="text-category"
                >แผนที่ :
              </span>
              <a
                class="text-category"
                :href="finalExamsList[0].examCenterProfile.location"
                target="_blank"
                >ดูแผนที่</a
              >
            </p>
          </div>
          <div v-if="finalExamsList.length > 0">
            <div
              class="row mb-5"
              v-for="(item, index) in finalExamsList"
              :key="index"
            >
              <div v-if="checkRoomRegister(item.id) == true ? true : false">
                <p class="text-title" style="font-weight: 600;">
                  {{ formatDate(item.startDate) }}
                </p>
                <div
                  :class="
                    listRegister.length == 0 || checkRegister(item.id) == true
                      ? 'col-12 mb-5 box-item'
                      : 'col-12 mb-5 item-disabled'
                  "
                  style="border-radius: 10px;border: 1px solid var(--9999-d-3, #9999D3);padding: 10px;"
                >
                  <div :class="item.limit > 0 ? 'exam-remaining' : 'exam-full'">
                    <p
                      class="text-category"
                      style="font-weight: 700;color: var(--9999-d-3, #9999D3);"
                    >
                      {{ item.examCenterProfile.name }}
                    </p>
                    <p
                      class="text-category"
                      style="color: var(--9999-d-3, #9999D3);"
                    >
                      <span style="font-weight: 700;">หลักสูตร : </span>
                      {{ item.syllabusProfile.name }}
                    </p>
                    <p
                      class="text-category"
                      style="color: var(--9999-d-3, #9999D3);"
                    >
                      <span style="font-weight: 700;">เลขห้องสอบ : </span
                      >{{ item.examRoomCode ? item.examRoomCode : "ไม่มี" }}
                    </p>
                    <p class="text-category" style="color: red;">
                      เวลาที่สอบ :
                      {{ checkStartDate(item.startDate, item.endDate) }}
                    </p>
                    <p
                      v-if="item.price != 0"
                      class="text-category"
                      style="color: red;"
                    >
                      ค่าเข้าสอบ :
                      {{ item.price }} บาท
                    </p>
                    <div
                      style="display: flex;
                              align-items: center;
                              justify-content: space-between;
                              flex-wrap: wrap;"
                    >
                      <span style="color: #6B6B6B;" class="text-category"
                        >( เหลือ
                        {{ item.limit }}
                        ที่ )</span
                      >
                      <button
                        v-if="checkRegister(item.id) == true"
                        class="btn"
                        style="border-radius: 11px;background: var(--da-595-a, #DA595A);color:#fff"
                        @click="registerExamRoom(item.id)"
                      >
                        ยกเลิกการจอง
                      </button>
                      <button
                        v-else
                        class="btn"
                        style="border-radius: 11px;background: var(--323563, #323563);color:#fff"
                        @click="registerExamRoom(item.id)"
                      >
                        จอง
                      </button>
                    </div>
                  </div>
                  <!-- <button
                  v-if="checkRegister(item.id)"
                  @click="cancelExamRoomRegister(item.id)"
                  class="btn btn-danger w-100"
                >
                  ยกเลิกการจอง
                </button> -->
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row mb-5">
            <div class="col-12" style="font-weight: 600;text-align: center;">
              ไม่พบข้อมูลห้องสอบ
            </div>
          </div>
        </div>
        <div class="modal-footer flex-end">
          <!-- <button
            class="btn"
            style="background: #323563; border-radius: 29px;color: #FFFFFF;width: 213px"
          >
            ยืนยัน
          </button> -->
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="modal fade" tabindex="-1" id="kt_modal_cert">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div style="text-align:center;" v-if="listCertificate">
            <div>
              <img src="media/image-app/image 10.svg" alt="" class="w-50" />
            </div>
            <div>
              <p class="text-title my-10">ขอมอบใบรับรองนี้ให้แก่</p>
              <p class="text-title my-20">
                {{ listCertificate.authorProfile.prefix }}
                {{ listCertificate.authorProfile.firstName }}
                {{ listCertificate.authorProfile.lastName }}
              </p>
              <p class="text-title my-10">
                เพื่อแสดงว่าได้ผ่านการฝึกอบรมออนไลน์หลักสูตร
              </p>
              <p class="text-title my-10" v-if="listCertificate.courseProfile">
                {{
                  listCertificate.courseProfile
                    ? listCertificate.courseProfile.title
                    : "-"
                }}
              </p>
              <p
                class="text-title my-10"
                v-if="listCertificate.syllabusProfile"
              >
                {{
                  listCertificate.syllabusProfile
                    ? listCertificate.syllabusProfile.name
                    : "-"
                }}
              </p>
              <p class="text-title my-10">
                ให้ไว้ ณ วันที่
                {{ formatDate(listCertificate.certApproveDate) }}
              </p>
              <p class="text-title" style="margin-top:15vh">
                (นางมัณลิกา สมพรานนท์)
              </p>
              <p class="text-title my-10">
                ผู้อำนวยการกองพัฒนาทรัพยากรบุคคลด้านพลังงาน ปฏิบัติราชการแทน
              </p>
              <p class="text-title my-10">
                อธิบดีกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            style="border-radius: 21px"
          >
            ปิด
          </button>
          <button
            type="button"
            class="btn "
            style="border-radius: 21px;background: #31B67C;color: #FFF;"
            @click="downloadCertificate"
          >
            ดาวน์โหลดใบรับรอง
          </button>
        </div>
      </div>
    </div>
  </div> -->
  <div class="modal fade" id="kt_modal_password" ref="modalPassword">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <h3 class="modal-title" style="text-align: center">
            กรุณากรอกรหัสห้องสอบ
          </h3>
          <p class="text-center">รหัสห้องสอบได้จากเจ้าหน้าที่ดูแลศูนย์สอบ</p>
        </div>
        <div class="modal-body text-center">
          <div style="position: relative;">
            <input
              class="form-control form-control-lg"
              :type="passwordFieldType"
              v-model="roomPwd"
            />
            <button
              style="position: absolute;top: 0;right: 5px;"
              type="button"
              class="btn"
              @click="switchVisibility"
            >
              <i class="fa fa-eye"></i>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            style="border-radius: 21px"
          >
            ยกเลิก
          </button>
          <button
            type="button"
            class="btn"
            style="
              color: var(--ffffff, #fff);
              border-radius: 29px;
              background: var(--323563, #323563);
            "
            @click="submitPassword"
          >
            ตกลง
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import Rating from "../Rating.vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { Modal } from "bootstrap";
import { hideModal, showModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
const video = ref();
const canvas = ref();
const captures = ref({});
const isCameraOpen = ref(false);
const listData = ref({
  totalPage: 0,
});
const limit = ref(6);
const page = ref(0);
const orderBy = ref(2);
const sortBy = ref(2);
const categories = ref([]);
const syllabus = ref();
const syllabusRegister = ref();
const listCertificate = ref();
const totalPage = ref(0);
const filterForm = ref({
  category: "",
  status: "",
  orderBy: "3",
  search: "",
});
const currentSpecialistStatus = ref(0);
const expireDate = ref();
const roomcode = ref();
const roomPwd = ref();
const flgPhoto = ref();
const modalPassword = ref<null | HTMLElement>(null);
const openTime = ref();

export default defineComponent({
  name: "mandatory-list",
  components: {
    Rating,
  },
  data() {
    return {
      categoryList: [],
      finalExamsList: [],
      examCenterList: [],
      examCenterId: "",
      countDown: 0,
      startDate: "",
      countDownTime: "",
      imageUrl: process.env.VUE_APP_IMG_URL,
      listRegister: [],
      componentKey: 0,
      passwordFieldType: "password",
      examRoomRegister2List: [],
      fileDownload: false,
    };
  },
  setup() {
    /* eslint-disable */

    const route = useRoute();
    const router = useRouter();
    onMounted(async () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getProfile?id=" +
            localStorage.getItem("u_id"),
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          currentSpecialistStatus.value =
            response.data.data.currentSpecialistStatus;
        })
        .catch((error) => {
          // console.log(error);
        });
      await getSyllabus();
      if (localStorage.getItem("id_token")) {
        await getSyllabusRegister();
      }
      await getDataList();
    });
    watch(
      () => route.path,
      () => {
        nextTick(async () => {
          await getSyllabus();
          if (localStorage.getItem("id_token")) {
            await getSyllabusRegister();
          }
          await getDataList();
        });
      }
    );
    const getSyllabus = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL + "/getSyllabus?id=" + route.params.id,
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          console.log("getSyllabus");
          console.log(response.data.data.content[0]);
          syllabus.value = response.data.data.content[0];
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const getSyllabusRegister = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getSyllabusRegister?syllabusId=" +
            route.params.id +
            "&userId=" +
            localStorage.getItem("u_id"),
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          syllabusRegister.value = response.data.data.content[0];
          console.log("syllabusRegister");
          console.log(response.data.data.content[0]);
          const currentDate = new Date(syllabusRegister.value.createDate);
          currentDate.setDate(currentDate.getDate() + syllabus.value.duration);
          expireDate.value = currentDate;
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const getDataList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getCourse?syllabusId=" +
            route.params.id +
            "&sizeContents=" +
            limit.value +
            "&page=" +
            page.value +
            "&orderBy=" +
            orderBy.value +
            "&sort=" +
            filterForm.value.orderBy +
            "&category=" +
            filterForm.value.category +
            "&registerStatus=" +
            filterForm.value.status +
            "&keyWord=" +
            filterForm.value.search,
          // "&categories=" +
          // categories.value
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          // console.log(response);
          listData.value = response.data.data.content;
          listData.value["totalElements"] = response.data.data.totalElements;
          totalPage.value = response.data.data.totalPages;
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const changePage = (pageEvent) => {
      page.value = pageEvent - 1;
      getDataList();
    };
    const backPage = () => {
      page.value = page.value - 1;
      getDataList();
    };
    const nextPage = () => {
      page.value = page.value + 1;
      getDataList();
    };
    const searchData = () => {
      getDataList();
    };
    const alert = () => {
      if (currentSpecialistStatus.value == 0) {
        Swal.fire({
          title:
            "การทดสอบหลักสูตรนี้สำหรับบุคคลากรด้านพลังงานเท่านั้น โปรดลงทะเบียนเพิ่มเติม และรออนุมัติจากเจ้าหน้าที่",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#323563",
          cancelButtonColor: "#d33",
          confirmButtonText: "ลงทะเบียนเพิ่มเติม",
          allowOutsideClick: false,
          cancelButtonText: "กลับสู่หน้าหลัก",
        }).then((result) => {
          if (result.isConfirmed) {
            router.push({ name: "profile" });
          }
        });
      } else if (currentSpecialistStatus.value == 1) {
        Swal.fire({
          title:
            "การทดสอบหลักสูตรนี้สำหรับบุคคลากรด้านพลังงานเท่านั้น โปรดลงทะเบียนเพิ่มเติม และรออนุมัติจากเจ้าหน้าที่",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#323563",
          cancelButtonColor: "#d33",
          confirmButtonText: "ตกลง",
          allowOutsideClick: false,
          cancelButtonText: "กลับสู่หน้าหลัก",
        }).then((result) => {});
      } else {
        Swal.fire({
          title:
            "การทดสอบหลักสูตรนี้สำหรับบุคคลากรด้านพลังงานเท่านั้น โปรดลงทะเบียนเพิ่มเติม และรออนุมัติจากเจ้าหน้าที่",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#323563",
          cancelButtonColor: "#d33",
          confirmButtonText: "ตกลง",
          allowOutsideClick: false,
          cancelButtonText: "กลับสู่หน้าหลัก",
        }).then((result) => {});
      }
    };
    const getCertificateId = async (id) => {
      const certificate = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getCourseRegister?courseId=" +
          id +
          "&userId=" +
          localStorage.getItem("u_id"),
        {
          headers: {
            token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
          },
        }
      );
      // console.log(certificate.data.data.content[0]);
      listCertificate.value = certificate.data.data.content[0];
      downloadCertificate();
    };
    const getCertificateSyllabusId = async (id) => {
      const certificate = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getSyllabusRegister?id=" +
          id +
          "&userId=" +
          localStorage.getItem("u_id"),
        {
          headers: {
            token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
          },
        }
      );
      // console.log("certificate");
      // console.log(certificate.data.data.content[0]);
      listCertificate.value = certificate.data.data.content[0];
      downloadCertificate();
    };
    const downloadCertificate = async () => {
      let finishDate   = "-"
      const director = await axios.get(
        process.env.VUE_APP_API_URL + "/getDirector",
        {
          headers: {
            token: localStorage.getItem("id_token"),
          },
        }
      );
      let date: String =
        new Date(listCertificate.value.certApproveDate).toLocaleDateString(
          "th-TH",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        ) + "";
        
        if (listCertificate.value.finishDate != null) {
          finishDate =  new Date(listCertificate.value.finishDate).toLocaleDateString(
          "th-TH",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        ) + "";
        }
       
      let course = listCertificate.value.syllabusProfile.name.replace("หลักสูตร","");
      const url =
        process.env.VUE_APP_REPORT_URL +
        "&title=" +
        listCertificate.value.authorProfile.prefix +
        "&course=" +
        course +
        "&firstname=" +
        listCertificate.value.authorProfile.firstName +
        "&lastname=" +
        listCertificate.value.authorProfile.lastName +
        "&date=" +
        arabic_to_thai(date) +
        "&license=" +
        process.env.VUE_APP_IMG_URL +
        director.data.data.signatureSyllabus +
        "&licenseName=" +
        director.data.data.firstNameSyllabus +
        " " +
        director.data.data.lastNameSyllabus+
        "&position=" + 
        director.data.data.positionSyllabus+
        "&examDate=" +
        arabic_to_thai(finishDate) +
        "&certNo=" +
        listCertificate.value.certNo;
        window.open(url.replace("/fileName", "/cert_dede.pdf"), "_blank");
    };
    const registerExamRoomBtn = async (id, flgPwd, photo, examRoomCode) => {
      if (
        openTime.value &&
        new Date().getTime() >= new Date(openTime.value).getTime()
      ) {
        roomcode.value = examRoomCode;
        flgPhoto.value = photo;
        if (flgPwd && photo) {
          const myModal = Modal.getInstance(modalPassword.value!);
          myModal?.show();
        } else if (flgPwd) {
          const myModal = Modal.getInstance(modalPassword.value!);
          myModal?.show();
        } else if (photo) {
          router.push({
            name: "verify",
            params: { examRoomCode: examRoomCode },
          });
        } else {
          const formData = new FormData();
          formData.append("examRoomCode", examRoomCode + "");
          await axios
            .post(
              process.env.VUE_APP_API_URL + "/enterExamRoomRegister",
              formData,
              {
                headers: {
                  token: localStorage.getItem("id_token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code == 200) {
                router.push({
                  name: "exam-detail",
                  params: { id: response.data.data.examRoomId },
                });
              } else {
                Swal.fire({
                  text: response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "ลองใหม่ภายหลัง!",
                  customClass: {
                    confirmButton: "btn fw-semobold btn-light-danger",
                  },
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                text: error.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "ลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            });
        }
      } else {
        Swal.fire({
          text: "ยังไม่ถึงเวลาเข้าสอบ",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "ลองใหม่ภายหลัง!",
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
      }
    };
    const submitPassword = async () => {
      hideModal(modalPassword.value);
      const formData = new FormData();
      formData.append("examRoomCode", roomcode!.value + "");
      formData.append("examRoomPwd", roomPwd!.value + "");
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/checkExamRoomRegister",
          formData,
          {
            headers: {
              token: localStorage.getItem("id_token"),
            },
          }
        )
        .then(async (response) => {
          if (response.data.code == 200) {
            if (flgPhoto.value == true) {
              router.push({
                name: "verify",
                params: { examRoomCode: roomcode.value },
              });
            } else {
              enterRoom();
            }
          } else {
            Swal.fire({
              text: response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "ลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };
    const enterRoom = async () => {
      const formData = new FormData();
      formData.append("examRoomCode", roomcode!.value + "");
      formData.append("examRoomPwd", roomPwd!.value + "");
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/enterExamRoomRegister",
          formData,
          {
            headers: {
              token: localStorage.getItem("id_token"),
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.code == 200) {
            router.push({
              name: "exam-detail",
              params: { id: response.data.data.examRoomId },
            });
          } else {
            Swal.fire({
              text: response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "ลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };
    const arabic_to_thai = (text) => {
    let data = text.replaceAll("1", "๑").replaceAll("2", "๒").replaceAll("3", "๓").replaceAll("4", "๔").replaceAll("5", "๕").replaceAll("6", "๖").replaceAll("7", "๗").replaceAll("8", "๘").replaceAll("9", "๙").replaceAll("0", "๐")
    return data
}
    return {
      video,
      canvas,
      captures,
      isCameraOpen,
      getDataList,
      changePage,
      backPage,
      nextPage,
      listData,
      limit,
      page,
      orderBy,
      sortBy,
      categories,
      filterForm,
      syllabus,
      expireDate,
      getSyllabus,
      searchData,
      totalPage,
      getSyllabusRegister,
      syllabusRegister,
      route,
      currentSpecialistStatus,
      alert,
      getCertificateId,
      downloadCertificate,
      getCertificateSyllabusId,
      listCertificate,
      registerExamRoomBtn,
      modalPassword,
      submitPassword,
      enterRoom,
      roomcode,
      roomPwd,
      flgPhoto,
      openTime,
      arabic_to_thai
    };
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    formatDate(date) {
      if (date != null) {
        const event = new Date(date);
        return event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      } else {
        return "-";
      }
    },
    checkCountDown(startDate) {
      this.countDown = window.setInterval(() => {
        const now = new Date().getTime();
        if (startDate) {
          const distance = new Date(startDate).getTime() - now;
          // Time calculations for days, hours, minutes and seconds
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          let hours: any = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          let minutes: any = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
          );
          let seconds: any = Math.floor((distance % (1000 * 60)) / 1000);
          if (hours < 10) hours = "0" + hours;
          if (minutes < 10) minutes = "0" + minutes;
          if (seconds < 10) seconds = "0" + seconds;

          // Display the result in the element with id="demo"
          // document.getElementById("demo").innerHTML =
          //   hours + " : " + minutes + " : " + seconds + "";
          this.countDownTime =
            days +
            " วัน " +
            hours +
            " ชั่วโมง " +
            minutes +
            " นาที " +
            seconds +
            " วินาที";
          // If the count down is finished, write some text
          if (distance < 0) {
            this.countDownTime =
              "0" +
              " วัน " +
              "0" +
              " ชั่วโมง " +
              "0" +
              " นาที " +
              "0" +
              " วินาที";
            clearInterval(this.countDown);
          }
        }
      }, 1000);
    },
    renderCategory(list) {
      if (list != undefined) {
        let data: any = [];
        for (const looplist of list) {
          data.push(looplist[0].data);
        }
        if (data.length > 0) {
          return data.join(",");
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
    renderStatus(status) {
      if (status == 1 || status == 2) {
        return "<span style='color:#9999D3'>เรียนต่อ</span>";
      } else if (status == 3) {
        return "<span style='color:#31B67C'>เรียนจบ</span>";
      } else if (status == 4) {
        return "<span style='color:#FF7786'>ไม่ผ่านหลักสูตร</span>";
      } else if (status == 5) {
        return "<span style='color:#F2A17D'>หมดเวลาเรียน</span>";
      } else {
        return "-";
      }
    },
    removeStyle(html) {
      if (html != undefined) {
        const regex = /(style=".+?")/gm;
        const str = html;
        const subst = ``;
        const result = str.replace(regex, subst);
        return result;
      } else {
        return "-";
      }
    },
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    registerExamRoom(examRoomId) {
      let updateDate = new Date(syllabusRegister.value.updateDate).getTime();
      let startDateRoomSelected;
      if (this.finalExamsList.length > 0) {
        for (const loopData of this.finalExamsList) {
          if (loopData["id"] == examRoomId) {
            startDateRoomSelected = new Date(loopData["startDate"]).getTime();
          }
        }
      }
      // console.log(updateDate, startDateRoomSelected);
      // console.log("registerExamRoom");
      // syllabusRegister.value.status == 5 &&
      if (
        syllabusRegister.value.status == 5 &&
        updateDate < startDateRoomSelected
      ) {
        if (this.checkRegister(examRoomId) == true) {
          Swal.fire({
            title: "ยืนยันการยกเลิกจองหรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#323563",
            cancelButtonColor: "#d33",
            confirmButtonText: "ตกลง",
            allowOutsideClick: false,
            cancelButtonText: "ยกเลิก",
          }).then(async (result) => {
            if (result.isConfirmed == true) {
              this.cancelExamRoomRegister(examRoomId);
            }
          });
        } else {
          Swal.fire({
            title: "ยืนยันการจองหรือไม่",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#323563",
            cancelButtonColor: "#d33",
            confirmButtonText: "ยืนยัน",
            allowOutsideClick: false,
            cancelButtonText: "ยกเลิก",
          }).then(async (result) => {
            const formData = new FormData();
            formData.append("examRoomId", examRoomId);
            axios
              .post(
                process.env.VUE_APP_API_URL + "/registerExamRoom",
                formData,
                {
                  headers: {
                    token: localStorage.getItem("id_token")
                      ? localStorage.getItem("id_token")
                      : localStorage.getItem("tokenGuest"),
                  },
                }
              )
              .then((response) => {
                if (response.data.code == 200) {
                  Swal.fire({
                    text: "ลงทะเบียนสำเร็จ!",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "ตกลง",
                    customClass: {
                      confirmButton: "btn fw-semobold btn-light-primary",
                    },
                  }).then(() => {
                    this.getExamRoomRegister();
                    this.getExamRoom();
                    this.forceRerender();
                  });
                } else {
                  Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "ลองใหม่ภายหลัง!",
                    customClass: {
                      confirmButton: "btn fw-semobold btn-light-danger",
                    },
                  });
                }
              })
              .catch((error) => {
                Swal.fire({
                  text: error,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "ลองใหม่ภายหลัง!",
                  customClass: {
                    confirmButton: "btn fw-semobold btn-light-danger",
                  },
                });
              });
          });
        }
      } else {
        // console.log(syllabusRegister.value.status);
        if (syllabusRegister.value.status == 3) {
          if (this.checkRegister(examRoomId) == true) {
            Swal.fire({
              title: "ยืนยันการยกเลิกจองหรือไม่",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#323563",
              cancelButtonColor: "#d33",
              confirmButtonText: "ตกลง",
              allowOutsideClick: false,
              cancelButtonText: "ยกเลิก",
            }).then(async (result) => {
              if (result.isConfirmed == true) {
                this.cancelExamRoomRegister(examRoomId);
              }
            });
          } else {
            Swal.fire({
              title: "ยืนยันการจองหรือไม่",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#323563",
              cancelButtonColor: "#d33",
              confirmButtonText: "ยืนยัน",
              allowOutsideClick: false,
              cancelButtonText: "ยกเลิก",
            }).then(async (result) => {
              const formData = new FormData();
              formData.append("examRoomId", examRoomId);
              axios
                .post(
                  process.env.VUE_APP_API_URL + "/registerExamRoom",
                  formData,
                  {
                    headers: {
                      token: localStorage.getItem("id_token")
                        ? localStorage.getItem("id_token")
                        : localStorage.getItem("tokenGuest"),
                    },
                  }
                )
                .then((response) => {
                  if (response.data.code == 200) {
                    Swal.fire({
                      text: "ลงทะเบียนสำเร็จ!",
                      icon: "success",
                      buttonsStyling: false,
                      confirmButtonText: "ตกลง",
                      customClass: {
                        confirmButton: "btn fw-semobold btn-light-primary",
                      },
                    }).then(() => {
                      this.getExamRoomRegister();
                      this.getExamRoom();
                      this.forceRerender();
                    });
                  } else {
                    Swal.fire({
                      text: response.data.message,
                      icon: "error",
                      buttonsStyling: false,
                      confirmButtonText: "ลองใหม่ภายหลัง!",
                      customClass: {
                        confirmButton: "btn fw-semobold btn-light-danger",
                      },
                    });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    text: error,
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "ลองใหม่ภายหลัง!",
                    customClass: {
                      confirmButton: "btn fw-semobold btn-light-danger",
                    },
                  });
                });
            });
          }
        } else {
          Swal.fire({
            text: "ไม่สามารถทำการจองได้ กรุณาจองในวันถัดไป",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        }
      }
    },
    checkStartDate(startDate, endDate) {
      let start = new Date(startDate);
      let end = new Date(endDate);
      // if (quota == 0) {
      //   return "เต็ม";
      // } else {
      //   if (date.getHours() > 12) {
      //     return "จองรอบบ่าย";
      //   } else {
      //     return "จองรอบเช้า";
      //   }
      // }
      return (
        start.toLocaleTimeString("th-TH", {
          hour: "2-digit",
          minute: "2-digit",
        }) +
        " - " +
        end.toLocaleTimeString("th-TH", {
          hour: "2-digit",
          minute: "2-digit",
        }) +
        " น."
      );
    },
    async getExamCenter() {
      const examCenter = await axios.get(
        process.env.VUE_APP_API_URL + "/getExamCenter",
        {
          headers: {
            token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
          },
        }
      );
      this.examCenterList = examCenter.data.data.content;
    },
    async getExamRoom() {
      let nowDate: any = new Date();
      let startDateStart: any = nowDate.setHours(0, 0, 0, 0);
      let endDateStart: any = new Date(nowDate).setDate(
        nowDate.getDate() + 160
      );
      endDateStart = new Date(endDateStart).setHours(23, 59, 59, 59);
      const finalExams = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getExamRoom?syllabusId=" +
          this.$route.params.id +
          "&examCenterId=" +
          this.examCenterId +
          "&sort=1" +
          "&startDateStart=" +
          startDateStart +
          "&endDateStart=" +
          endDateStart +
          "&enable=1",
        {
          headers: {
            token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
          },
        }
      );
      // console.log("finalExamsList");
      // console.log(new Date(startDateStart) + "-" + new Date(endDateStart));
      // console.log(finalExams.data.data.content);
      this.finalExamsList = finalExams.data.data.content;
      if (this.finalExamsList.length > 0) {
        openTime.value = this.finalExamsList[0]["startDate"];
      }
    },

    getExamRoomRegister() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getExamRoomRegister?userId=" +
            localStorage.getItem("u_id") +
            "&status=0,1&isDetail=true",
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          let list: any = [];
          for (const loopData of response.data.data.content) {
            if (loopData.startTestDate != null) {
              if (
                new Date(loopData.startTestDate).getTime() >
                new Date().setHours(0, 0, 0)
              ) {
                list.push(loopData);
              }
            } else {
              if (
                new Date(loopData.examRoomProfile.endDate).getTime() >
                new Date().setHours(0, 0, 0)
              ) {
                list.push(loopData);
              }
            }
          }
          this.listRegister = list;
          if (list.length > 0) {
            this.examCenterId = list[0].examRoomProfile.examCenterId;
          }
          this.getExamRoom();
          // console.log("listRegister");
          // console.log(list);
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    async cancelExamRoomRegister(examRoomId) {
      const examRoomRegister = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getExamRoomRegister?examRoomId=" +
          examRoomId +
          "&userId=" +
          localStorage.getItem("u_id"),
        {
          headers: {
            token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
          },
        }
      );
      const formData = new FormData();
      formData.append("id", examRoomRegister.data.data.content[0].id);
      axios
        .post(
          process.env.VUE_APP_API_URL + "/cancelExamRoomRegister",
          formData,
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          this.getExamRoomRegister();
          this.getExamRoom();
          this.forceRerender();
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    checkRegister(id) {
      if (this.listRegister.length > 0) {
        for (const loopData of this.listRegister) {
          if (loopData["examRoomId"] == id) {
            return true;
          }
        }
      } else {
        return false;
      }
    },
    registerSyllabus(courseId) {
      const formData = new FormData();
      formData.append("syllabusId", this.$route.params.id + "");
      if (localStorage.getItem("id_token")) {
        axios
          .post(process.env.VUE_APP_API_URL + "/registerSyllabus", formData, {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          })
          .then((response) => {
            if (response.data.code == 200) {
              Swal.fire({
                title: "ลงทะเบียนสำเร็จ",
                text: "รายการของคุณลงทะเบียนเรียบร้อยแล้ว",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "ตกลง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-primary",
                },
              }).then((res) => {
                if (courseId != undefined) {
                  this.$router.push({
                    name: "mandatory-detail",
                    params: { sbId: this.$route.params.id, cId: courseId },
                  });
                } else {
                  this.$router.go(0);
                }
              });
            } else {
              this.$router.go(0);
            }
          })
          .catch((error) => {
            this.$router.push({ name: "sign-in" });
          });
      } else {
        this.$router.push({ name: "sign-in" });
      }
    },
    checkRoomRegister(idRoom) {
      // console.log("checkRoomRegister");
      let show = true;
      if (this.examRoomRegister2List.length > 0) {
        for (const loopRegister of this.examRoomRegister2List) {
          if (loopRegister["examRoomId"] == idRoom) {
            show = false;
          }
        }
      }
      return show;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    fileShow() {
      if (this.fileDownload == false) {
        this.fileDownload = true;
      } else {
        this.fileDownload = false;
      }
    },
  },
  async mounted() {
    const modal_password = document.getElementById("kt_modal_password")!;
    const modal = new Modal(modal_password);
    // console.log(modal);

    if (!localStorage.getItem("alert")) {
      Swal.fire({
        title:
          "หลักสูตรนี้สำหรับผู้ที่ต้องการแต่งตั้งเป็นผู้รับผิดชอบด้านพลังงาน เมื่อเรียนจบครบตามที่หลักสูตรกำหนด จะต้องมีการยืนยันตัวตนเพื่อให้เป็นไปตามพระราชบัญญัติการส่งเสริมการอนุรักษ์พลังงาน พ.ศ.2535 (แก้ไขเพิ่มเติม พ.ศ.2550)ก่อนจองกำหนดการสอบเพื่อขอใบรับรอง",
        input: "checkbox",
        inputPlaceholder: "ไม่ต้องแสดงในวันนี้อีก",
        confirmButtonText: "เข้าใจแล้ว",
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value) {
            localStorage.setItem("alert", "false");
          }
        }
      });
    }
    // Swal.fire({
    //     title: "Loading",
    //     allowOutsideClick: false,
    //     onBeforeOpen: () => {
    //       Swal.showLoading();
    //     },
    //   });
    const category = await axios.get(
      process.env.VUE_APP_API_URL + "/category?categoryType=course",
      {
        headers: {
          token: localStorage.getItem("id_token")
            ? localStorage.getItem("id_token")
            : localStorage.getItem("tokenGuest"),
        },
      }
    );
    this.categoryList = category.data.data;
    this.getExamCenter();
    if (localStorage.getItem("id_token")) {
      this.getExamRoomRegister();
      setTimeout(async () => {
        const startTime = await axios.get(
          process.env.VUE_APP_API_URL +
            "/getSyllabusRegister?syllabusId=" +
            this.$route.params.id +
            "&userId=" +
            localStorage.getItem("u_id"),
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        );
        if (startTime.data.data.content.length > 0) {
          const dataTime = new Date(startTime.data.data.content[0].createDate);
          dataTime.setDate(dataTime.getDate() + syllabus.value.duration);
          this.checkCountDown(dataTime);
        }
      }, 500);
    }
    const examRoomRegister2 = await axios.get(
      process.env.VUE_APP_API_URL +
        "/getExamRoomRegister?userId=" +
        localStorage.getItem("u_id") +
        "&status=2&isDetail=true",
      {
        headers: {
          token: localStorage.getItem("id_token")
            ? localStorage.getItem("id_token")
            : localStorage.getItem("tokenGuest"),
        },
      }
    );
    // console.log("examRoomRegister2");
    // console.log(examRoomRegister2);
    this.examRoomRegister2List = examRoomRegister2.data.data.content;
  },
});
</script>
<style>
.el-input__inner {
  background: var(--e-9-e-9-ef, #e9e9ef);
  border: 0px;
  border-radius: 10px;
}
</style>
