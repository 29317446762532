
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import Rating from "../Rating.vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { Modal } from "bootstrap";
import { hideModal, showModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
const video = ref();
const canvas = ref();
const captures = ref({});
const isCameraOpen = ref(false);
const listData = ref({
  totalPage: 0,
});
const limit = ref(6);
const page = ref(0);
const orderBy = ref(2);
const sortBy = ref(2);
const categories = ref([]);
const syllabus = ref();
const syllabusRegister = ref();
const listCertificate = ref();
const totalPage = ref(0);
const filterForm = ref({
  category: "",
  status: "",
  orderBy: "3",
  search: "",
});
const currentSpecialistStatus = ref(0);
const expireDate = ref();
const roomcode = ref();
const roomPwd = ref();
const flgPhoto = ref();
const modalPassword = ref<null | HTMLElement>(null);
const openTime = ref();

export default defineComponent({
  name: "mandatory-list",
  components: {
    Rating,
  },
  data() {
    return {
      categoryList: [],
      finalExamsList: [],
      examCenterList: [],
      examCenterId: "",
      countDown: 0,
      startDate: "",
      countDownTime: "",
      imageUrl: process.env.VUE_APP_IMG_URL,
      listRegister: [],
      componentKey: 0,
      passwordFieldType: "password",
      examRoomRegister2List: [],
      fileDownload: false,
    };
  },
  setup() {
    /* eslint-disable */

    const route = useRoute();
    const router = useRouter();
    onMounted(async () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getProfile?id=" +
            localStorage.getItem("u_id"),
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          currentSpecialistStatus.value =
            response.data.data.currentSpecialistStatus;
        })
        .catch((error) => {
          // console.log(error);
        });
      await getSyllabus();
      if (localStorage.getItem("id_token")) {
        await getSyllabusRegister();
      }
      await getDataList();
    });
    watch(
      () => route.path,
      () => {
        nextTick(async () => {
          await getSyllabus();
          if (localStorage.getItem("id_token")) {
            await getSyllabusRegister();
          }
          await getDataList();
        });
      }
    );
    const getSyllabus = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL + "/getSyllabus?id=" + route.params.id,
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          console.log("getSyllabus");
          console.log(response.data.data.content[0]);
          syllabus.value = response.data.data.content[0];
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const getSyllabusRegister = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getSyllabusRegister?syllabusId=" +
            route.params.id +
            "&userId=" +
            localStorage.getItem("u_id"),
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          syllabusRegister.value = response.data.data.content[0];
          console.log("syllabusRegister");
          console.log(response.data.data.content[0]);
          const currentDate = new Date(syllabusRegister.value.createDate);
          currentDate.setDate(currentDate.getDate() + syllabus.value.duration);
          expireDate.value = currentDate;
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const getDataList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getCourse?syllabusId=" +
            route.params.id +
            "&sizeContents=" +
            limit.value +
            "&page=" +
            page.value +
            "&orderBy=" +
            orderBy.value +
            "&sort=" +
            filterForm.value.orderBy +
            "&category=" +
            filterForm.value.category +
            "&registerStatus=" +
            filterForm.value.status +
            "&keyWord=" +
            filterForm.value.search,
          // "&categories=" +
          // categories.value
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          // console.log(response);
          listData.value = response.data.data.content;
          listData.value["totalElements"] = response.data.data.totalElements;
          totalPage.value = response.data.data.totalPages;
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const changePage = (pageEvent) => {
      page.value = pageEvent - 1;
      getDataList();
    };
    const backPage = () => {
      page.value = page.value - 1;
      getDataList();
    };
    const nextPage = () => {
      page.value = page.value + 1;
      getDataList();
    };
    const searchData = () => {
      getDataList();
    };
    const alert = () => {
      if (currentSpecialistStatus.value == 0) {
        Swal.fire({
          title:
            "การทดสอบหลักสูตรนี้สำหรับบุคคลากรด้านพลังงานเท่านั้น โปรดลงทะเบียนเพิ่มเติม และรออนุมัติจากเจ้าหน้าที่",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#323563",
          cancelButtonColor: "#d33",
          confirmButtonText: "ลงทะเบียนเพิ่มเติม",
          allowOutsideClick: false,
          cancelButtonText: "กลับสู่หน้าหลัก",
        }).then((result) => {
          if (result.isConfirmed) {
            router.push({ name: "profile" });
          }
        });
      } else if (currentSpecialistStatus.value == 1) {
        Swal.fire({
          title:
            "การทดสอบหลักสูตรนี้สำหรับบุคคลากรด้านพลังงานเท่านั้น โปรดลงทะเบียนเพิ่มเติม และรออนุมัติจากเจ้าหน้าที่",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#323563",
          cancelButtonColor: "#d33",
          confirmButtonText: "ตกลง",
          allowOutsideClick: false,
          cancelButtonText: "กลับสู่หน้าหลัก",
        }).then((result) => {});
      } else {
        Swal.fire({
          title:
            "การทดสอบหลักสูตรนี้สำหรับบุคคลากรด้านพลังงานเท่านั้น โปรดลงทะเบียนเพิ่มเติม และรออนุมัติจากเจ้าหน้าที่",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#323563",
          cancelButtonColor: "#d33",
          confirmButtonText: "ตกลง",
          allowOutsideClick: false,
          cancelButtonText: "กลับสู่หน้าหลัก",
        }).then((result) => {});
      }
    };
    const getCertificateId = async (id) => {
      const certificate = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getCourseRegister?courseId=" +
          id +
          "&userId=" +
          localStorage.getItem("u_id"),
        {
          headers: {
            token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
          },
        }
      );
      // console.log(certificate.data.data.content[0]);
      listCertificate.value = certificate.data.data.content[0];
      downloadCertificate();
    };
    const getCertificateSyllabusId = async (id) => {
      const certificate = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getSyllabusRegister?id=" +
          id +
          "&userId=" +
          localStorage.getItem("u_id"),
        {
          headers: {
            token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
          },
        }
      );
      // console.log("certificate");
      // console.log(certificate.data.data.content[0]);
      listCertificate.value = certificate.data.data.content[0];
      downloadCertificate();
    };
    const downloadCertificate = async () => {
      let finishDate   = "-"
      const director = await axios.get(
        process.env.VUE_APP_API_URL + "/getDirector",
        {
          headers: {
            token: localStorage.getItem("id_token"),
          },
        }
      );
      let date: String =
        new Date(listCertificate.value.certApproveDate).toLocaleDateString(
          "th-TH",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        ) + "";
        
        if (listCertificate.value.finishDate != null) {
          finishDate =  new Date(listCertificate.value.finishDate).toLocaleDateString(
          "th-TH",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        ) + "";
        }
       
      let course = listCertificate.value.syllabusProfile.name.replace("หลักสูตร","");
      const url =
        process.env.VUE_APP_REPORT_URL +
        "&title=" +
        listCertificate.value.authorProfile.prefix +
        "&course=" +
        course +
        "&firstname=" +
        listCertificate.value.authorProfile.firstName +
        "&lastname=" +
        listCertificate.value.authorProfile.lastName +
        "&date=" +
        arabic_to_thai(date) +
        "&license=" +
        process.env.VUE_APP_IMG_URL +
        director.data.data.signatureSyllabus +
        "&licenseName=" +
        director.data.data.firstNameSyllabus +
        " " +
        director.data.data.lastNameSyllabus+
        "&position=" + 
        director.data.data.positionSyllabus+
        "&examDate=" +
        arabic_to_thai(finishDate) +
        "&certNo=" +
        listCertificate.value.certNo;
        window.open(url.replace("/fileName", "/cert_dede.pdf"), "_blank");
    };
    const registerExamRoomBtn = async (id, flgPwd, photo, examRoomCode) => {
      if (
        openTime.value &&
        new Date().getTime() >= new Date(openTime.value).getTime()
      ) {
        roomcode.value = examRoomCode;
        flgPhoto.value = photo;
        if (flgPwd && photo) {
          const myModal = Modal.getInstance(modalPassword.value!);
          myModal?.show();
        } else if (flgPwd) {
          const myModal = Modal.getInstance(modalPassword.value!);
          myModal?.show();
        } else if (photo) {
          router.push({
            name: "verify",
            params: { examRoomCode: examRoomCode },
          });
        } else {
          const formData = new FormData();
          formData.append("examRoomCode", examRoomCode + "");
          await axios
            .post(
              process.env.VUE_APP_API_URL + "/enterExamRoomRegister",
              formData,
              {
                headers: {
                  token: localStorage.getItem("id_token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code == 200) {
                router.push({
                  name: "exam-detail",
                  params: { id: response.data.data.examRoomId },
                });
              } else {
                Swal.fire({
                  text: response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "ลองใหม่ภายหลัง!",
                  customClass: {
                    confirmButton: "btn fw-semobold btn-light-danger",
                  },
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                text: error.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "ลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            });
        }
      } else {
        Swal.fire({
          text: "ยังไม่ถึงเวลาเข้าสอบ",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "ลองใหม่ภายหลัง!",
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
      }
    };
    const submitPassword = async () => {
      hideModal(modalPassword.value);
      const formData = new FormData();
      formData.append("examRoomCode", roomcode!.value + "");
      formData.append("examRoomPwd", roomPwd!.value + "");
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/checkExamRoomRegister",
          formData,
          {
            headers: {
              token: localStorage.getItem("id_token"),
            },
          }
        )
        .then(async (response) => {
          if (response.data.code == 200) {
            if (flgPhoto.value == true) {
              router.push({
                name: "verify",
                params: { examRoomCode: roomcode.value },
              });
            } else {
              enterRoom();
            }
          } else {
            Swal.fire({
              text: response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "ลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };
    const enterRoom = async () => {
      const formData = new FormData();
      formData.append("examRoomCode", roomcode!.value + "");
      formData.append("examRoomPwd", roomPwd!.value + "");
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/enterExamRoomRegister",
          formData,
          {
            headers: {
              token: localStorage.getItem("id_token"),
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.code == 200) {
            router.push({
              name: "exam-detail",
              params: { id: response.data.data.examRoomId },
            });
          } else {
            Swal.fire({
              text: response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "ลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };
    const arabic_to_thai = (text) => {
    let data = text.replaceAll("1", "๑").replaceAll("2", "๒").replaceAll("3", "๓").replaceAll("4", "๔").replaceAll("5", "๕").replaceAll("6", "๖").replaceAll("7", "๗").replaceAll("8", "๘").replaceAll("9", "๙").replaceAll("0", "๐")
    return data
}
    return {
      video,
      canvas,
      captures,
      isCameraOpen,
      getDataList,
      changePage,
      backPage,
      nextPage,
      listData,
      limit,
      page,
      orderBy,
      sortBy,
      categories,
      filterForm,
      syllabus,
      expireDate,
      getSyllabus,
      searchData,
      totalPage,
      getSyllabusRegister,
      syllabusRegister,
      route,
      currentSpecialistStatus,
      alert,
      getCertificateId,
      downloadCertificate,
      getCertificateSyllabusId,
      listCertificate,
      registerExamRoomBtn,
      modalPassword,
      submitPassword,
      enterRoom,
      roomcode,
      roomPwd,
      flgPhoto,
      openTime,
      arabic_to_thai
    };
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    formatDate(date) {
      if (date != null) {
        const event = new Date(date);
        return event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      } else {
        return "-";
      }
    },
    checkCountDown(startDate) {
      this.countDown = window.setInterval(() => {
        const now = new Date().getTime();
        if (startDate) {
          const distance = new Date(startDate).getTime() - now;
          // Time calculations for days, hours, minutes and seconds
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          let hours: any = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          let minutes: any = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
          );
          let seconds: any = Math.floor((distance % (1000 * 60)) / 1000);
          if (hours < 10) hours = "0" + hours;
          if (minutes < 10) minutes = "0" + minutes;
          if (seconds < 10) seconds = "0" + seconds;

          // Display the result in the element with id="demo"
          // document.getElementById("demo").innerHTML =
          //   hours + " : " + minutes + " : " + seconds + "";
          this.countDownTime =
            days +
            " วัน " +
            hours +
            " ชั่วโมง " +
            minutes +
            " นาที " +
            seconds +
            " วินาที";
          // If the count down is finished, write some text
          if (distance < 0) {
            this.countDownTime =
              "0" +
              " วัน " +
              "0" +
              " ชั่วโมง " +
              "0" +
              " นาที " +
              "0" +
              " วินาที";
            clearInterval(this.countDown);
          }
        }
      }, 1000);
    },
    renderCategory(list) {
      if (list != undefined) {
        let data: any = [];
        for (const looplist of list) {
          data.push(looplist[0].data);
        }
        if (data.length > 0) {
          return data.join(",");
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
    renderStatus(status) {
      if (status == 1 || status == 2) {
        return "<span style='color:#9999D3'>เรียนต่อ</span>";
      } else if (status == 3) {
        return "<span style='color:#31B67C'>เรียนจบ</span>";
      } else if (status == 4) {
        return "<span style='color:#FF7786'>ไม่ผ่านหลักสูตร</span>";
      } else if (status == 5) {
        return "<span style='color:#F2A17D'>หมดเวลาเรียน</span>";
      } else {
        return "-";
      }
    },
    removeStyle(html) {
      if (html != undefined) {
        const regex = /(style=".+?")/gm;
        const str = html;
        const subst = ``;
        const result = str.replace(regex, subst);
        return result;
      } else {
        return "-";
      }
    },
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    registerExamRoom(examRoomId) {
      let updateDate = new Date(syllabusRegister.value.updateDate).getTime();
      let startDateRoomSelected;
      if (this.finalExamsList.length > 0) {
        for (const loopData of this.finalExamsList) {
          if (loopData["id"] == examRoomId) {
            startDateRoomSelected = new Date(loopData["startDate"]).getTime();
          }
        }
      }
      // console.log(updateDate, startDateRoomSelected);
      // console.log("registerExamRoom");
      // syllabusRegister.value.status == 5 &&
      if (
        syllabusRegister.value.status == 5 &&
        updateDate < startDateRoomSelected
      ) {
        if (this.checkRegister(examRoomId) == true) {
          Swal.fire({
            title: "ยืนยันการยกเลิกจองหรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#323563",
            cancelButtonColor: "#d33",
            confirmButtonText: "ตกลง",
            allowOutsideClick: false,
            cancelButtonText: "ยกเลิก",
          }).then(async (result) => {
            if (result.isConfirmed == true) {
              this.cancelExamRoomRegister(examRoomId);
            }
          });
        } else {
          Swal.fire({
            title: "ยืนยันการจองหรือไม่",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#323563",
            cancelButtonColor: "#d33",
            confirmButtonText: "ยืนยัน",
            allowOutsideClick: false,
            cancelButtonText: "ยกเลิก",
          }).then(async (result) => {
            const formData = new FormData();
            formData.append("examRoomId", examRoomId);
            axios
              .post(
                process.env.VUE_APP_API_URL + "/registerExamRoom",
                formData,
                {
                  headers: {
                    token: localStorage.getItem("id_token")
                      ? localStorage.getItem("id_token")
                      : localStorage.getItem("tokenGuest"),
                  },
                }
              )
              .then((response) => {
                if (response.data.code == 200) {
                  Swal.fire({
                    text: "ลงทะเบียนสำเร็จ!",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "ตกลง",
                    customClass: {
                      confirmButton: "btn fw-semobold btn-light-primary",
                    },
                  }).then(() => {
                    this.getExamRoomRegister();
                    this.getExamRoom();
                    this.forceRerender();
                  });
                } else {
                  Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "ลองใหม่ภายหลัง!",
                    customClass: {
                      confirmButton: "btn fw-semobold btn-light-danger",
                    },
                  });
                }
              })
              .catch((error) => {
                Swal.fire({
                  text: error,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "ลองใหม่ภายหลัง!",
                  customClass: {
                    confirmButton: "btn fw-semobold btn-light-danger",
                  },
                });
              });
          });
        }
      } else {
        // console.log(syllabusRegister.value.status);
        if (syllabusRegister.value.status == 3) {
          if (this.checkRegister(examRoomId) == true) {
            Swal.fire({
              title: "ยืนยันการยกเลิกจองหรือไม่",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#323563",
              cancelButtonColor: "#d33",
              confirmButtonText: "ตกลง",
              allowOutsideClick: false,
              cancelButtonText: "ยกเลิก",
            }).then(async (result) => {
              if (result.isConfirmed == true) {
                this.cancelExamRoomRegister(examRoomId);
              }
            });
          } else {
            Swal.fire({
              title: "ยืนยันการจองหรือไม่",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#323563",
              cancelButtonColor: "#d33",
              confirmButtonText: "ยืนยัน",
              allowOutsideClick: false,
              cancelButtonText: "ยกเลิก",
            }).then(async (result) => {
              const formData = new FormData();
              formData.append("examRoomId", examRoomId);
              axios
                .post(
                  process.env.VUE_APP_API_URL + "/registerExamRoom",
                  formData,
                  {
                    headers: {
                      token: localStorage.getItem("id_token")
                        ? localStorage.getItem("id_token")
                        : localStorage.getItem("tokenGuest"),
                    },
                  }
                )
                .then((response) => {
                  if (response.data.code == 200) {
                    Swal.fire({
                      text: "ลงทะเบียนสำเร็จ!",
                      icon: "success",
                      buttonsStyling: false,
                      confirmButtonText: "ตกลง",
                      customClass: {
                        confirmButton: "btn fw-semobold btn-light-primary",
                      },
                    }).then(() => {
                      this.getExamRoomRegister();
                      this.getExamRoom();
                      this.forceRerender();
                    });
                  } else {
                    Swal.fire({
                      text: response.data.message,
                      icon: "error",
                      buttonsStyling: false,
                      confirmButtonText: "ลองใหม่ภายหลัง!",
                      customClass: {
                        confirmButton: "btn fw-semobold btn-light-danger",
                      },
                    });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    text: error,
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "ลองใหม่ภายหลัง!",
                    customClass: {
                      confirmButton: "btn fw-semobold btn-light-danger",
                    },
                  });
                });
            });
          }
        } else {
          Swal.fire({
            text: "ไม่สามารถทำการจองได้ กรุณาจองในวันถัดไป",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        }
      }
    },
    checkStartDate(startDate, endDate) {
      let start = new Date(startDate);
      let end = new Date(endDate);
      // if (quota == 0) {
      //   return "เต็ม";
      // } else {
      //   if (date.getHours() > 12) {
      //     return "จองรอบบ่าย";
      //   } else {
      //     return "จองรอบเช้า";
      //   }
      // }
      return (
        start.toLocaleTimeString("th-TH", {
          hour: "2-digit",
          minute: "2-digit",
        }) +
        " - " +
        end.toLocaleTimeString("th-TH", {
          hour: "2-digit",
          minute: "2-digit",
        }) +
        " น."
      );
    },
    async getExamCenter() {
      const examCenter = await axios.get(
        process.env.VUE_APP_API_URL + "/getExamCenter",
        {
          headers: {
            token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
          },
        }
      );
      this.examCenterList = examCenter.data.data.content;
    },
    async getExamRoom() {
      let nowDate: any = new Date();
      let startDateStart: any = nowDate.setHours(0, 0, 0, 0);
      let endDateStart: any = new Date(nowDate).setDate(
        nowDate.getDate() + 160
      );
      endDateStart = new Date(endDateStart).setHours(23, 59, 59, 59);
      const finalExams = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getExamRoom?syllabusId=" +
          this.$route.params.id +
          "&examCenterId=" +
          this.examCenterId +
          "&sort=1" +
          "&startDateStart=" +
          startDateStart +
          "&endDateStart=" +
          endDateStart +
          "&enable=1",
        {
          headers: {
            token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
          },
        }
      );
      // console.log("finalExamsList");
      // console.log(new Date(startDateStart) + "-" + new Date(endDateStart));
      // console.log(finalExams.data.data.content);
      this.finalExamsList = finalExams.data.data.content;
      if (this.finalExamsList.length > 0) {
        openTime.value = this.finalExamsList[0]["startDate"];
      }
    },

    getExamRoomRegister() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getExamRoomRegister?userId=" +
            localStorage.getItem("u_id") +
            "&status=0,1&isDetail=true",
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          let list: any = [];
          for (const loopData of response.data.data.content) {
            if (loopData.startTestDate != null) {
              if (
                new Date(loopData.startTestDate).getTime() >
                new Date().setHours(0, 0, 0)
              ) {
                list.push(loopData);
              }
            } else {
              if (
                new Date(loopData.examRoomProfile.endDate).getTime() >
                new Date().setHours(0, 0, 0)
              ) {
                list.push(loopData);
              }
            }
          }
          this.listRegister = list;
          if (list.length > 0) {
            this.examCenterId = list[0].examRoomProfile.examCenterId;
          }
          this.getExamRoom();
          // console.log("listRegister");
          // console.log(list);
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    async cancelExamRoomRegister(examRoomId) {
      const examRoomRegister = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getExamRoomRegister?examRoomId=" +
          examRoomId +
          "&userId=" +
          localStorage.getItem("u_id"),
        {
          headers: {
            token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
          },
        }
      );
      const formData = new FormData();
      formData.append("id", examRoomRegister.data.data.content[0].id);
      axios
        .post(
          process.env.VUE_APP_API_URL + "/cancelExamRoomRegister",
          formData,
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          this.getExamRoomRegister();
          this.getExamRoom();
          this.forceRerender();
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    checkRegister(id) {
      if (this.listRegister.length > 0) {
        for (const loopData of this.listRegister) {
          if (loopData["examRoomId"] == id) {
            return true;
          }
        }
      } else {
        return false;
      }
    },
    registerSyllabus(courseId) {
      const formData = new FormData();
      formData.append("syllabusId", this.$route.params.id + "");
      if (localStorage.getItem("id_token")) {
        axios
          .post(process.env.VUE_APP_API_URL + "/registerSyllabus", formData, {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          })
          .then((response) => {
            if (response.data.code == 200) {
              Swal.fire({
                title: "ลงทะเบียนสำเร็จ",
                text: "รายการของคุณลงทะเบียนเรียบร้อยแล้ว",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "ตกลง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-primary",
                },
              }).then((res) => {
                if (courseId != undefined) {
                  this.$router.push({
                    name: "mandatory-detail",
                    params: { sbId: this.$route.params.id, cId: courseId },
                  });
                } else {
                  this.$router.go(0);
                }
              });
            } else {
              this.$router.go(0);
            }
          })
          .catch((error) => {
            this.$router.push({ name: "sign-in" });
          });
      } else {
        this.$router.push({ name: "sign-in" });
      }
    },
    checkRoomRegister(idRoom) {
      // console.log("checkRoomRegister");
      let show = true;
      if (this.examRoomRegister2List.length > 0) {
        for (const loopRegister of this.examRoomRegister2List) {
          if (loopRegister["examRoomId"] == idRoom) {
            show = false;
          }
        }
      }
      return show;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    fileShow() {
      if (this.fileDownload == false) {
        this.fileDownload = true;
      } else {
        this.fileDownload = false;
      }
    },
  },
  async mounted() {
    const modal_password = document.getElementById("kt_modal_password")!;
    const modal = new Modal(modal_password);
    // console.log(modal);

    if (!localStorage.getItem("alert")) {
      Swal.fire({
        title:
          "หลักสูตรนี้สำหรับผู้ที่ต้องการแต่งตั้งเป็นผู้รับผิดชอบด้านพลังงาน เมื่อเรียนจบครบตามที่หลักสูตรกำหนด จะต้องมีการยืนยันตัวตนเพื่อให้เป็นไปตามพระราชบัญญัติการส่งเสริมการอนุรักษ์พลังงาน พ.ศ.2535 (แก้ไขเพิ่มเติม พ.ศ.2550)ก่อนจองกำหนดการสอบเพื่อขอใบรับรอง",
        input: "checkbox",
        inputPlaceholder: "ไม่ต้องแสดงในวันนี้อีก",
        confirmButtonText: "เข้าใจแล้ว",
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value) {
            localStorage.setItem("alert", "false");
          }
        }
      });
    }
    // Swal.fire({
    //     title: "Loading",
    //     allowOutsideClick: false,
    //     onBeforeOpen: () => {
    //       Swal.showLoading();
    //     },
    //   });
    const category = await axios.get(
      process.env.VUE_APP_API_URL + "/category?categoryType=course",
      {
        headers: {
          token: localStorage.getItem("id_token")
            ? localStorage.getItem("id_token")
            : localStorage.getItem("tokenGuest"),
        },
      }
    );
    this.categoryList = category.data.data;
    this.getExamCenter();
    if (localStorage.getItem("id_token")) {
      this.getExamRoomRegister();
      setTimeout(async () => {
        const startTime = await axios.get(
          process.env.VUE_APP_API_URL +
            "/getSyllabusRegister?syllabusId=" +
            this.$route.params.id +
            "&userId=" +
            localStorage.getItem("u_id"),
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        );
        if (startTime.data.data.content.length > 0) {
          const dataTime = new Date(startTime.data.data.content[0].createDate);
          dataTime.setDate(dataTime.getDate() + syllabus.value.duration);
          this.checkCountDown(dataTime);
        }
      }, 500);
    }
    const examRoomRegister2 = await axios.get(
      process.env.VUE_APP_API_URL +
        "/getExamRoomRegister?userId=" +
        localStorage.getItem("u_id") +
        "&status=2&isDetail=true",
      {
        headers: {
          token: localStorage.getItem("id_token")
            ? localStorage.getItem("id_token")
            : localStorage.getItem("tokenGuest"),
        },
      }
    );
    // console.log("examRoomRegister2");
    // console.log(examRoomRegister2);
    this.examRoomRegister2List = examRoomRegister2.data.data.content;
  },
});
